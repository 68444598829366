import { MessagesMockApi } from 'app/mock-api/common/messages/api';
import { NavigationMockApi } from 'app/mock-api/common/navigation/api';
import { NotificationsMockApi } from 'app/mock-api/common/notifications/api';
import { ShortcutsMockApi } from 'app/mock-api/common/shortcuts/api';
import { UserMockApi } from 'app/mock-api/common/user/api';
import { UsersInventoryMockApi } from "./dashboards/users/inventory/api";
import { AuthMockApi } from "./common/auth/api";

export const mockApiServices = [
    MessagesMockApi,
    NavigationMockApi,
    NotificationsMockApi,
    ShortcutsMockApi,
    UserMockApi,
    UsersInventoryMockApi,
    AuthMockApi,
];
