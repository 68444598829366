import { Injectable } from '@angular/core';
import { FuseMockApiService, FuseMockApiUtils } from '@fuse/lib/mock-api';
import { brands as brandsData, categories as categoriesData, products as productsData, tags as tagsData, vendors as vendorsData, users as usersData } from 'app/mock-api/dashboards/users/inventory/data';
import { assign, cloneDeep } from 'lodash-es';

@Injectable({providedIn: 'root'})
export class UsersInventoryMockApi
{
    private _categories: any[] = categoriesData;
    private _brands: any[] = brandsData;
    private _products: any[] = productsData;
    private _tags: any[] = tagsData;
    private _vendors: any[] = vendorsData;

    private _users: any[] = usersData;

    private _apiUrl = 'http://localhost:5000/api/users/';
    //private _apiUrl = 'api/dashboards/users/inventory/users';


    /**
     * Constructor
     */
    constructor(private _fuseMockApiService: FuseMockApiService)
    {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void
    {
        // -----------------------------------------------------------------------------------------------------
        // @ Categories - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/dashboards/users/inventory/categories')
            .reply(() => [200, cloneDeep(this._categories)]);

        // -----------------------------------------------------------------------------------------------------
        // @ Brands - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/dashboards/users/inventory/brands')
            .reply(() => [200, cloneDeep(this._brands)]);

        // -----------------------------------------------------------------------------------------------------
        // @ Products - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/dashboards/users/inventory/products', 300)
            .reply(({request}) =>
            {
                // Get available queries
                const search = request.params.get('search');
                const sort = request.params.get('sort') || 'name';
                const order = request.params.get('order') || 'asc';
                const page = parseInt(request.params.get('page') ?? '1', 10);
                const size = parseInt(request.params.get('size') ?? '10', 10);

                // Clone the products
                let products: any[] | null = cloneDeep(this._products);

                // Sort the products
                if ( sort === 'sku' || sort === 'name' || sort === 'active' )
                {
                    products.sort((a, b) =>
                    {
                        const fieldA = a[sort].toString().toUpperCase();
                        const fieldB = b[sort].toString().toUpperCase();
                        return order === 'asc' ? fieldA.localeCompare(fieldB) : fieldB.localeCompare(fieldA);
                    });
                }
                else
                {
                    products.sort((a, b) => order === 'asc' ? a[sort] - b[sort] : b[sort] - a[sort]);
                }

                // If search exists...
                if ( search )
                {
                    // Filter the products
                    products = products.filter(contact => contact.name && contact.name.toLowerCase().includes(search.toLowerCase()));
                }

                // Paginate - Start
                const productsLength = products.length;

                // Calculate pagination details
                const begin = page * size;
                const end = Math.min((size * (page + 1)), productsLength);
                const lastPage = Math.max(Math.ceil(productsLength / size), 1);

                // Prepare the pagination object
                let pagination = {};

                // If the requested page number is bigger than
                // the last possible page number, return null for
                // products but also send the last possible page so
                // the app can navigate to there
                if ( page > lastPage )
                {
                    products = null;
                    pagination = {
                        lastPage,
                    };
                }
                else
                {
                    // Paginate the results by size
                    products = products.slice(begin, end);

                    // Prepare the pagination mock-api
                    pagination = {
                        length    : productsLength,
                        size      : size,
                        page      : page,
                        lastPage  : lastPage,
                        startIndex: begin,
                        endIndex  : end - 1,
                    };
                }

                // Return the response
                return [
                    200,
                    {
                        products,
                        pagination,
                    },
                ];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ Users - GET
        // -----------------------------------------------------------------------------------------------------
        // this._fuseMockApiService
        //     .onGet(this._apiUrl, 300)
        //     .reply(({request}) =>
        //     {
        //         // Get available queries
        //         const search = request.params.get('search');
        //         const sort = request.params.get('sort') || 'name';
        //         const order = request.params.get('order') || 'asc';
        //         const page = parseInt(request.params.get('page') ?? '1', 10);
        //         const size = parseInt(request.params.get('size') ?? '10', 10);
        //
        //         // Clone the products
        //         let users: any[] | null = cloneDeep(this._users);
        //
        //         // Sort the products
        //         if ( sort === 'uid' || sort === 'email' || sort === 'active' )
        //         {
        //             users.sort((a, b) =>
        //             {
        //                 const fieldA = a[sort].toString().toUpperCase();
        //                 const fieldB = b[sort].toString().toUpperCase();
        //                 return order === 'asc' ? fieldA.localeCompare(fieldB) : fieldB.localeCompare(fieldA);
        //             });
        //         }
        //         else
        //         {
        //             users.sort((a, b) => order === 'asc' ? a[sort] - b[sort] : b[sort] - a[sort]);
        //         }
        //
        //         // If search exists...
        //         if ( search )
        //         {
        //             // Filter the products
        //             users = users.filter(contact => contact.email && contact.email.toLowerCase().includes(search.toLowerCase()));
        //         }
        //
        //         // Paginate - Start
        //         const usersLength = users.length;
        //
        //         // Calculate pagination details
        //         const begin = page * size;
        //         const end = Math.min((size * (page + 1)), usersLength);
        //         const lastPage = Math.max(Math.ceil(usersLength / size), 1);
        //
        //         // Prepare the pagination object
        //         let pagination = {};
        //
        //         // If the requested page number is bigger than
        //         // the last possible page number, return null for
        //         // products but also send the last possible page so
        //         // the app can navigate to there
        //         if ( page > lastPage )
        //         {
        //             users = null;
        //             pagination = {
        //                 lastPage,
        //             };
        //         }
        //         else
        //         {
        //             // Paginate the results by size
        //             users = users.slice(begin, end);
        //
        //             // Prepare the pagination mock-api
        //             pagination = {
        //                 length    : usersLength,
        //                 size      : size,
        //                 page      : page,
        //                 lastPage  : lastPage,
        //                 startIndex: begin,
        //                 endIndex  : end - 1,
        //             };
        //         }
        //
        //         // Return the response
        //         return [
        //             200,
        //             {
        //                 users,
        //                 pagination,
        //             },
        //         ];
        //     });

        // -----------------------------------------------------------------------------------------------------
        // @ Product - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/dashboards/users/inventory/product')
            .reply(({request}) =>
            {
                // Get the id from the params
                const id = request.params.get('id');

                // Clone the products
                const products = cloneDeep(this._products);

                // Find the product
                const product = products.find(item => item.id === id);

                // Return the response
                return [200, product];
            });

        // -----------------------------------------------------------------------------------------------------
        // @ User - GET
        // -----------------------------------------------------------------------------------------------------
        // this._fuseMockApiService
        //     .onGet('api/dashboards/users/inventory/user')
        //     .reply(({request}) =>
        //     {
        //         // Get the id from the params
        //         const id = request.params.get('uid');
        //
        //         // Clone the products
        //         const users = cloneDeep(this._users);
        //
        //         // Find the product
        //         const user = users.find(item => item.id === id);
        //
        //         // Return the response
        //         return [200, user];
        //     });

        // -----------------------------------------------------------------------------------------------------
        // @ Tags - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/dashboards/users/inventory/tags')
            .reply(() => [200, cloneDeep(this._tags)]);

        // -----------------------------------------------------------------------------------------------------
        // @ Vendors - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/dashboards/users/inventory/vendors')
            .reply(() => [200, cloneDeep(this._vendors)]);
    }
}
