import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { LayoutComponent } from 'app/layout/layout.component';
import { AuthFireGuard } from "./core/authFire/guardsFire/authFire.guard";
import { PreviewTemplateSmsComponent } from './modules/admin/dashboards/preview-template-sms/preview-template-sms.component';
import subscriptionRoutes from "./modules/admin/dashboards/subscription/subscription.routes";
//import {EmailTemplateComponent} from "./modules/admin/dashboards/users/inventory/email-template/email-template.component";

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/dashboards/project'
    {path: '', pathMatch : 'full', redirectTo: 'dashboards/users/inventory'},
    {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'dashboards/users/inventory'},
    //{path: 'dashboards/users/inventory/template/', pathMatch: 'full', redirectTo: 'dashboards/users/inventory/template/'},
    // {
    //     path: 'dashboards/users/inventory/template',
    //     component: EmailTemplateComponent,
    // },
    // Auth routes for guests
    {
        path: '',
        // canActivate: [NoAuthGuard],
        // canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes')},
        ]
    },

    {
        path: 'preview',
        data: {
          layout: 'empty'
        },
        children: [
          {
            path: ':id',
            component: PreviewTemplateSmsComponent
          }
        ]
      },
    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthFireGuard],
        canActivateChild: [AuthFireGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.routes')},
        ]
    },

    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.routes')},
        ]
    },
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.routes')},
        ]
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'home', loadChildren: () => import('app/modules/landing/home/home.routes')},
        ]
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthFireGuard],
        canActivateChild: [AuthFireGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        children: [

            // Dashboards
            {path: 'dashboards', children: [
                {path: 'users', loadChildren: () => import('app/modules/admin/dashboards/users/users.routes')},
                {path: 'photos', loadChildren: () => import('app/modules/admin/dashboards/photos/photos.routes')},
                {path: 'template', loadChildren: () => import('app/modules/admin/dashboards/template/template.routes')},
                {path: 'settings', loadChildren: () => import('app/modules/admin/dashboards/settings/settings.routes')},
                {path: 'subscription', loadChildren: () => import('app/modules/admin/dashboards/subscription/subscription.routes')},
                {path: 'messaging', loadChildren: () => import('app/modules/admin/dashboards/messaging/messaging.routes')}
                ]},
        ],
    },
];
