<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
    class="dark bg-black print:hidden"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation.default"
    [opened]="!isScreenSmall">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="flex items-center h-20 p-6 pb-0 mt-4">
            <!-- Light version -->
            <img
                class="dark:hidden w-30"
                src="assets/images/logo/SB_US_LogoBW3.png"
                alt="Logo image">
            <!-- Dark version -->
            <img
                class="hidden dark:flex w-30"
                src="assets/images/logo/SB_US_LogoBW3.png"
                alt="Logo image">
        </div>
        <!-- "Hello" div -->
        <div class="flex items-center pt-4">

        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <!-- Navigation toggle button -->
        <button
            mat-icon-button
            (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
        </button>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
<!--            <user [showAvatar]="false"></user>-->
            <button
                data-test-id="signout-button"
                mat-flat-button
                [color]="'primary'"
                (click)="signOut()">
                <span class="ml-2 mr-1"> Sign Out </span>
                <mat-icon svgIcon="heroicons_outline:arrow-right-on-rectangle"></mat-icon>
            </button>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">Copyright &copy; Selfiebot 2024</span>
    </div>

</div>

