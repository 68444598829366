import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {MatButtonModule} from "@angular/material/button";

@Component({
  selector: 'app-preview-template-sms',
  standalone: true,
    imports: [CommonModule, MatButtonModule],
  templateUrl: './preview-template-sms.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PreviewTemplateSmsComponent implements OnInit {
  id: string;
  backgroundColor: string;
  htmlText = '';
  hasFocus = false;
  settings: any;
  photoURL: string;
  width: string;
  height: string;
  align: string;
  subject: string;
  imageUrl: string | ArrayBuffer;
  selectedFile: File | null = null;
  apiUrl = environment.apiUrl;
  presetH: string;
  presetW: string;
  colorForm: UntypedFormGroup;
  buttonStyle: UntypedFormGroup;
  isLoading: boolean = true;
  userImageURL: string;
  errorMessage: string;

  constructor(private route: ActivatedRoute,
    private http: HttpClient,
    private fb: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    private sanitizer: DomSanitizer
  ) { }

  async ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    await this.getEmailTemplate();
  }

  openLink() {
    const buttonLink = this.buttonStyle.get('buttonLink').value;
    window.open(buttonLink, '_blank');
  }

  async getEmailTemplate() {
    try {
      const url = `${this.apiUrl}/template/${this.id}`;
      const response: any = await this.http.get(url).toPromise();
      this.settings = response.templateSettings;
      if (this.settings) {
        this.photoURL = response.templateSettings.logo.photoURL;
        this.width = response.templateSettings.logo.width;
        this.height = response.templateSettings.logo.height;
        this.align = response.templateSettings.logo.align;
        this.presetW = this.width;
        this.presetH = this.height;
        let backgroundColor = response.templateSettings.templateBackgroundColor;
        let wysiwygHTML = response.templateSettings.wysiwygHTML;
        let buttonBackgroundColor =
          response.templateSettings.buttonStyling.backgroundColor;
        let buttonHref = response.templateSettings.buttonStyling.buttonHref;
        let borderRadius = response.templateSettings.buttonStyling.borderRadius;
        let showButton = response.templateSettings.buttonStyling.showButton;
        let textColor = response.templateSettings.buttonStyling.textColor;
        let buttonText = response.templateSettings.buttonStyling.buttonText;
        let buttonFont = response.templateSettings.buttonStyling.buttonFont;
        this.initButtonSettingsForm(
          buttonBackgroundColor,
          buttonHref,
          borderRadius,
          textColor,
          showButton,
          buttonText,
          buttonFont
        );
        this.initBackgroundColorForm(backgroundColor);
        this.htmlText = wysiwygHTML;
        this.wysiwygHTMLWithCss = this.htmlText
      }
      this.userImageURL = response.photoURL;
    } catch (error) {
      this.errorMessage = error.error.message;
      console.error('Error fetching template:', error);
    } finally {
      this.isLoading = false; // Set loading state to false
      this.cdr.detectChanges();
    }
  }
  wysiwygHTMLWithCss;

  transformHtml(htmlTextWithStyle): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(htmlTextWithStyle);
  }

  initButtonSettingsForm(
    buttonBackgroundColor,
    buttonHref,
    borderRadius,
    textColor,
    showButton,
    buttonText,
    buttonFont
  ) {
    this.buttonStyle = this.fb.group({
      buttonText: [buttonText, Validators.required],
      buttonLink: [
        buttonHref,
        [
          Validators.required,
          Validators.pattern(
            'https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()!@:%_\\+.~#?&\\/\\/=]*)'
          ),
        ],
      ],
      buttonBackgroundColor: [buttonBackgroundColor],
      buttonTextColor: [textColor],
      buttonBorderRadius: [borderRadius],
      buttonFont: [buttonFont],
      showButton: [showButton],
    });
  }
  initBackgroundColorForm(background) {
    this.colorForm = this.fb.group({
      backgroundColor: [background],
    });
  }


    redirectToSelfiebot() {
        window.open('https://www.selfiebot.co/', '_blank');
    }
}
