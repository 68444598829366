import {Injectable, NgZone} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, map, Observable, of, throwError } from 'rxjs';
import { getAuth, signInWithEmailAndPassword, Auth, UserCredential } from 'firebase/auth';
import { MatDialog } from '@angular/material/dialog';
import { initializeApp } from "firebase/app";
import { AuthFireUtils } from "./authFire.utils";
import { UserService } from '../user/user.service';
import { inject } from '@angular/core';
import {environment} from "../../../environments/environment";
import {sendPasswordResetEmail} from "@angular/fire/auth";
import {AngularFireAuth} from "@angular/fire/compat/auth";

@Injectable({
    providedIn: 'root',
})
export class AuthFireService {

    private readonly auth!: Auth;
    user: any;
    private _authenticated: boolean = false;
    private _userService = inject(UserService);

    endPointAuth = environment.apiUrlAuth;
    endPoint = environment.apiUrl;
    endPointFrontend = environment.frontendUrl;

    constructor(
        private http: HttpClient,
        private router: Router,
        public dialog: MatDialog,
        private _activatedRoute: ActivatedRoute,
        private ngZone: NgZone,
        private afAuth: AngularFireAuth)
    {
        // Initialize Firebase app if not already initialized
        const firebaseApp = initializeApp(environment.firebaseConfig);
        this.auth = getAuth(firebaseApp);
        this.user = this.auth.currentUser;
    }

    getAuth() {
        return this.afAuth;
    }

    resetPasswordInit(email: string) {
        return this.afAuth.sendPasswordResetEmail(
            email,
            { url: this.endPointFrontend + '/sign-in' });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string)
    {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string
    {
        return localStorage.getItem('accessToken') ?? '';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    async signIn(credentials: {
        email: string;
        password: string;
    }): Promise<UserCredential> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            throwError('User is already logged in.');
        }

        try {
            const result = await signInWithEmailAndPassword(
                this.auth,
                credentials.email,
                credentials.password
            );
            await this.getTokenAndSendToBackend();
            //console.log(token);
            return result;
        } catch (error) {
            console.error('Login failed ', error);
            throw error;
        }
    }

    async signOut(): Promise<void> {
        await this.auth.signOut();
        await new Promise<void>((resolve) => {
            const unsubscribe = this.auth.onAuthStateChanged(async () => {
                (await unsubscribe)();
                resolve();
            });
        });
        // Set the authenticated flag to false
        this._authenticated = false;

        // Remove the access token from the local storage
        localStorage.removeItem('accessToken');
        return;
    }

    async sendLinkResetPassword(email: string): Promise<void> {
        await this.ngZone.run(() => {
            return sendPasswordResetEmail(this.auth, email)
                .then(() => {
                    console.log('Password reset email sent successfully!');
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    console.error('Error sending reset password email:', errorCode, errorMessage);
                });
        });
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------


    private async getTokenAndSendToBackend(): Promise<void> {
        try {
            const idToken = await this.auth.currentUser?.getIdToken(true);
            if (idToken) {
                const headers = new HttpHeaders({
                    Authorization: 'Bearer ' + idToken,
                });
                this.http.get(this.endPointAuth, { headers }).subscribe(
                    (response: any) => {

                        // Store the access token in the local storage
                        this.accessToken = idToken;

                        this._userService.user = response;

                        // Set the authenticated flag to true
                        this._authenticated = true;

                        // Redirect to dashboard for now
                        const redirectURL =
                            this._activatedRoute.snapshot.queryParamMap.get(
                                'redirectURL'
                            ) || '/signed-in-redirect';
                        this.router.navigateByUrl(redirectURL);
                    },
                    (error) => {
                        //this.isUserLoggedIn$.next(false);
                        console.error(
                            'Error sending ID token to backend:',
                            error
                        );
                        return;
                    }
                );
            }
        } catch (error) {
            console.error('Error getting ID Token:', error);
        }
    }

    check(): Observable<boolean>
    {
        // Check if the user is logged in
        if ( this._authenticated )
        {
            return of(true);
        }

        // Check the access token availability
        if ( !this.accessToken )
        {
            return of(false);
        }

        // Check the access token expire date
        if ( AuthFireUtils.isTokenExpired(this.accessToken) )
        {
            return of(false);
        }

        // If the access token exists, and it didn't expire, sign in using it
        return this.signInUsingToken();
    }

    signInUsingToken(): Observable<boolean> {
        const idToken = localStorage.getItem('accessToken');

        if (!idToken) {
            return of(false);
        }

        const headers = new HttpHeaders({
            Authorization: 'Bearer ' + idToken,
        });

        return this.http.get(this.endPointAuth, { headers }).pipe(
            map((response:  any) => {
                this.accessToken = idToken;
                this._userService.user = response;
                this._authenticated = true;
                const redirectURL =
                    this._activatedRoute.snapshot.queryParamMap.get(
                        'redirectURL'
                    ) || '/signed-in-redirect';
                this.router.navigateByUrl(redirectURL);
                return true;
            }),
            catchError((error) => {
                console.error('Error sending ID token to backend:', error);
                return of(false);
            })
        );
    }
}
