/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id      : 'dashboards',
        title   : 'Dashboards',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [
            {
                id   : 'dashboards.users',
                title: 'Home',
                type : 'basic',
                icon : 'heroicons_outline:home',
                link : '/dashboards/users/inventory',
            },
            {
                id   : 'dashboards.photos',
                title: 'Photos',
                type : 'basic',
                icon : 'heroicons_outline:photo',
                link : '/dashboards/photos',
            },
            {
                id: 'dashboards.template',
                title: 'Email template',
                type: 'basic',
                icon: 'feather:edit',
                link: '/dashboards/template',
            },
            {
                id   : 'dashboards.settings',
                title: 'Settings',
                type : 'basic',
                icon : 'heroicons_outline:cog-8-tooth',
                link : '/dashboards/settings',
            },
            {
                id   : 'dashboards.subscription',
                title: 'Subscription',
                type : 'basic',
                icon : 'heroicons_outline:banknotes',
                link : '/dashboards/subscription',
            },
            {
                id   : 'dashboards.messaging',
                title: 'Messaging',
                type : 'basic',
                icon : 'mat_outline:send',
                link : '/dashboards/messaging',
            }
        ],
    },
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id      : 'dashboards',
        title   : 'Dashboards',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [
            {
                id   : 'dashboards.users',
                title: 'Users',
                type : 'basic',
                icon : 'heroicons_outline:user-group',
                link : '/dashboards/users/inventory',
            },
        ],
    },
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id      : 'dashboards',
        title   : 'Dashboards',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [
            {
                id   : 'dashboards.users',
                title: 'Users',
                type : 'basic',
                icon : 'heroicons_outline:user-group',
                link : '/dashboards/users/inventory',
            },
        ],
    },
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id      : 'dashboards',
        title   : 'Dashboards',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [
            {
                id   : 'dashboards.users',
                title: 'Users',
                type : 'basic',
                icon : 'heroicons_outline:user-group',
                link : '/dashboards/users/inventory',
            },
        ],
    },
];
