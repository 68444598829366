<div class="h-[100vh] w-[100vw] flex items-center justify-center" *ngIf="!settings && userImageURL">
    <img class="w-180 h-180" [src]="userImageURL" />
</div>
<div *ngIf="settings && userImageURL" class="bg-gray-200 w-[100vw] h-full">
    <div class="h-full">
        <div *ngIf="isLoading" class="flex items-center justify-center">
            <p>Loading...</p>
        </div>

        <div *ngIf="errorMessage" class="flex items-center justify-center">
            <p class="text-red-500 font-bold">{{errorMessage}}</p>
        </div>

        <div *ngIf="!isLoading && !errorMessage && settings && userImageURL"
            class="h-full flex items-center justify-center">
            <div [style.background]="colorForm?.get('backgroundColor')?.value"
                (colorPickerChange)="colorForm?.get('backgroundColor').setValue($event)" style="
                        height: 100%;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        padding: 20px;
                    ">
                <div data-test-id="preview-logo" class="mb-5 mt-6 w-full xsm:w-4/5 sm:w-4/5 lg:w-2/6 xl:w-2/7"
                    [ngStyle]="{'display':'flex', 'justify-content': align, 'width': '400px'}">
                    <img [style.width.px]="presetW" [style.height.px]="presetH" [style.max-width.px]="200"
                        [style.max-height.px]="150" [src]="photoURL" alt="Image" style="object-fit: cover" />
                </div>
                <div class="w-full xsm:w-4/5 sm:w-4/5 lg:w-2/6 xl:w-2/7">
                    <img [src]="userImageURL" />
                </div>
                <div style="
                            height: 100px;
                            width: 400px;
                            margin-top: 20px;
                            background-color: transparent;
                        ">
                    <!-- Add content for the new div here -->
                    <div style="border-width: 0">
                        <div data-test-id="preview-quill" class="ql-editor" style="
                                    height: 100px;
                                    width: 400px;
                                    background-color: transparent;
                                    overflow:hidden;
                                " [innerHTML]="
                                    transformHtml(htmlText)
                                ">
                            {{ htmlText }}
                        </div>
                    </div>
                </div>
                <div *ngIf="buttonStyle?.value.showButton">
                    <button data-test-id="preview-button" [disabled]="
                                                    buttonStyle
                                                        .get('buttonLink')
                                                        .hasError('pattern') ||
                                                    buttonStyle
                                                        .get('buttonLink')
                                                        .hasError('required')
                                                " mat-flat-button class="mt-4 px-4 py-2 mb-4" (click)="openLink()"
                        [style.background-color]="
                                                    buttonStyle.value.buttonBackgroundColor
                                                " [style.border-radius]="buttonStyle.value.buttonBorderRadius"
                        [style.font-family]="
                                                buttonStyle.value.buttonFont" [style.color]="
                                                    buttonStyle.value.buttonTextColor">
                        {{
                        buttonStyle.value.buttonText

                        }}
                    </button>
                </div>
                <div *ngIf="settings?.showAdvertisement" class="mb-4">
                    <button mat-flat-button class="bg-white text-black rounded-lg" (click)="redirectToSelfiebot()">
                        Buy a Selfiebot
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
